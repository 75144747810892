import React, {FC, useContext} from "react";
import styles from "./styles/SpotlightTerrace.module.scss";
import FontAwesome from "../../../utilities/FontAwesome";
import modalStyles from "../../../modals/styles/Modal.module.scss";
import {StoreContext} from "../../../../stores/StoreLoader";


interface ISpotlightCurationProps {
    item: ISpotlightTile;
    terraceObj: ISpotlightTerrace;
    idx: number;
    handleDelete: () => void;
    dragHandleRef?: (element: HTMLElement | null) => void;
}

const SpotlightTerraceTileCurationContainer: FC<ISpotlightCurationProps> = ({
    item,
    terraceObj,
    idx,
    handleDelete,
    dragHandleRef,
}) => {
    const { modalStore } = useContext(StoreContext);


    return (
        <div className={styles.curationContainer}>

            {/* Edit button */}
            <button
                aria-label={'Edit Spotlight Tile'}
                onClick={e => {
                    e.stopPropagation(); // Prevent toggling content in parent
                    modalStore.addModal({
                        type: 'spotlightTileEditor',
                        terraceObj,
                        tileObj: item,
                        intent: 'edit',
                        index: idx,
                    });
                }}
            >
                <FontAwesome ariaHidden prefix={"fas"} name={"fa-pencil-alt"} />
            </button>

            {/* Delete button */}
            <button
                aria-label={'Delete Spotlight Tile'}
                onClick={e => {
                    e.stopPropagation(); // Prevent toggling content in parent
                    modalStore.addModal({
                        type: 'blockui',
                        idleContent: () => (
                            <div id="sb-fluxRestoreDialog" style={{ cursor: "default" }}>
                                <h3 style={{ marginBottom: "1em" }}>
                                    Do you want to delete the {item.title} tile?
                                </h3>
                                <button className="btn btn-default" onClick={modalStore.removeTop}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default btn-info"
                                    onClick={handleDelete}
                                >
                                    Yes
                                </button>
                            </div>
                        ),
                        pendingContent: () => (
                            <div id="sb-fluxRestoreDialogMessageRestoring">
                                <h3>
                                    <FontAwesome
                                        name="fa-spinner"
                                        prefix={"fas"}
                                        spin
                                        style={{ marginRight: "0.5em" }}
                                    />
                                    Deleting...
                                </h3>
                            </div>
                        ),
                        failureContent: () => (
                            <div id="sb-fluxRestoreDialogMessageRestoreFailed">
                                <h3>
                                    Hmmm... Something went wrong and we couldn&apos;t delete the tile!
                                </h3>
                            </div>
                        ),
                        onConfirm: handleDelete,
                        closeEffect: () => {
                            document.body.classList.remove(modalStyles.modalOpen);
                        },
                    });
                }}
            >
                <FontAwesome ariaHidden prefix={"fas"} name={"fa-trash"} />
            </button>

            {/* Drag handle button (only if there are multiple tiles) */}
            {terraceObj.json_data?.spotlight?.tiles?.length > 1 && (
                <button
                    className={styles.dragHandle}
                    aria-label={'Rearrange Spotlight Tile'}
                    ref={dragHandleRef}
                    onClick={(e) => e.stopPropagation()} // Prevent toggling content in parent
                >
                    <FontAwesome ariaHidden prefix={"fas"} name={"fa-arrows"} />
                </button>
            )}
        </div>
    );
};

export default SpotlightTerraceTileCurationContainer;
