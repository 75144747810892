import React, {lazy, Suspense, useCallback, useContext, useRef, useState} from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import BlockTitle from "../_BlockTitle";
import SpotlightTerraceTile from "./SpotlightTerraceTile";

import {ITerraceProps} from "../Terrace";
import {StoreContext} from "../../../../stores/StoreLoader";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import useSwipe from "../../../../hooks/useSwipe";
import styles from './styles/SpotlightTerrace.module.scss';
import NotificationManager from "../../../notifications/NotificationManager";

const DraggableSpotlightTile = lazy(() => import('./DraggableSpotlightTile'));

interface SpotlightBlockProps extends ITerraceProps {
    blockObj: ISpotlightTerrace;
}

const SpotlightBlock = observer((props: SpotlightBlockProps) => {
    const { userStore, modalStore } = useContext(StoreContext);
    const [isDragging, setIsDragging] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTransitioningOut, setIsTransitioningOut] = useState(false);
    const [isTransitioningIn, setIsTransitioningIn] = useState(false);


    const tiles = props.blockObj.json_data?.spotlight?.tiles || [];

    const containerRef = useRef<HTMLDivElement>(null);

    const swipeRef = useSwipe({
        handleLeftSwipe: useCallback(() => {
            setActiveIndex(prev => Math.min(prev + 1, tiles.length - 1));
        }, [tiles.length]),
        handleRightSwipe: useCallback(() => {
            setActiveIndex(prev => Math.max(prev - 1, 0));
        }, []),
    });



    const handleClick = () => {
        if (tiles.length < 8) {


            modalStore.addModal({
                type: 'spotlightTileEditor',
                terraceObj: props.blockObj,
                intent: 'add',
                index: tiles.length,
            });
        }
        else {

            NotificationManager.info('You have reached the maximum number of tiles allowed in a Spotlight Terrace.', 'Max Tiles Reached', 5000);
        }

    }

    const spotlightBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add Tile',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus" />,
            onClick: handleClick,
        },
    ];

    function toggleExpand() {
        const totalTiles = tiles.length;

        // Expanding:
        // rotateToCenter(0.5s) + rotate-to-slide-delay(0.3s) + slideDownFromCenter(0.8s) ≈ 1.6s total for the top tile
        // Each additional tile adds ~0.1s
        const expandAnimationTime = 1600 + (totalTiles - 1) * 100; // in ms

        // Collapsing:
        // CascadeUp(0.8s) + per-tile delay(0.1s each)
        // Base collapse time: 0.8s = 2.2s (2200ms)
        // Add (totalTiles-1)*0.1s for stagger
        const collapseAnimationTime = 800 + (totalTiles - 1) * 100; // in ms

        if (isExpanded) {
            // Collapse
            requestAnimationFrame(() => { setIsTransitioningIn(true); });
            setTimeout(() => {
                setIsExpanded(false);
                setIsTransitioningIn(false);
            }, collapseAnimationTime);
            if (containerRef.current) {
                containerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Expand
            setIsTransitioningOut(true);
            setTimeout(() => {
                setIsExpanded(true);
                setIsTransitioningOut(false);
            }, expandAnimationTime);
        }
    }



    const tileContainerClassNames = classNames(
        styles.tilesContainer,
        {
            [styles.tilesContainerExpanded]: isExpanded,
            [styles.transitioningOut]: isTransitioningOut,
            [styles.transitioningIn]: isTransitioningIn,
        }
    );

    return (
        <section ref={containerRef}>
            <BlockTitle
                blockObj={props.blockObj}
                aboveTheFold={props.aboveTheFold}
                showViewMore={false}
                dropDownItems={spotlightBlockDropDownItems}
            />
            <div className={styles.aacontentContainer}>
                {userStore.isEditor ? (
                    <div
                        key={isTransitioningIn ? 'transitioning' : 'static'}
                        style={{
                            '--total-tiles': String(tiles.length),
                        } as React.CSSProperties}
                        ref={swipeRef}
                        className={tileContainerClassNames}
                    >
                        {tiles.map((item, idx) => (
                            <Suspense fallback={<div>Loading Draggable Tiles...</div>} key={item.id}>
                                <DraggableSpotlightTile
                                    key={item.id}
                                    item={item}
                                    index={idx}
                                    terraceObj={props.blockObj}
                                    isDragging={isDragging}
                                    setIsDragging={setIsDragging}
                                    dataActive={idx === activeIndex}
                                    setActiveIndex={setActiveIndex}
                                    dataPosition={
                                        idx === activeIndex
                                            ? "active"
                                            : idx === activeIndex + 1
                                                ? "next"
                                                : idx === activeIndex + 2
                                                    ? "after"
                                                    : idx === activeIndex - 1
                                                        ? "previous"
                                                        : idx === activeIndex - 2
                                                            ? "before"
                                                            : "hidden"
                                    }
                                    isExpanded={isExpanded}
                                    // Cast custom CSS props to strings for type safety
                                    style={{
                                        '--animation-index': String(idx),
                                        '--total-tiles': String(tiles.length),

                                    } as React.CSSProperties}
                                />
                            </Suspense>
                        ))}
                    </div>
                ) : (
                    <div
                        style={{
                            '--total-tiles': String(tiles.length),
                        } as React.CSSProperties}
                        ref={swipeRef}
                        className={tileContainerClassNames}
                    >
                        {tiles.map((item, idx) => {
                            const position =
                                idx === activeIndex
                                    ? "active"
                                    : idx === activeIndex + 1
                                        ? "next"
                                        : idx === activeIndex + 2
                                            ? "after"
                                            : idx === activeIndex - 1
                                                ? "previous"
                                                : idx === activeIndex - 2
                                                    ? "before"
                                                    : "hidden";

                            return (
                                <SpotlightTerraceTile
                                    key={item.id}
                                    item={item}
                                    terraceObj={props.blockObj}
                                    idx={idx}
                                    setActiveIndex={setActiveIndex}
                                    dataActive={idx === activeIndex}
                                    dataPosition={position}
                                    isExpanded={isExpanded}
                                    style={{
                                        '--animation-index': String(idx),
                                        '--total-tiles': String(tiles.length),
                                    } as React.CSSProperties}
                                />
                            );
                        })}
                    </div>
                )}
                <div className={styles.expandButtonContainer}>
                    <button
                        key={isExpanded ? 'collapse' : 'expand'}
                        className={styles.expandButton}
                        onClick={toggleExpand}
                    >
                        <FontAwesome prefix={'fas'} fixedWidth
                                     className={isExpanded ? "fa-caret-up" : "fa-caret-down"}/> {isExpanded ? "Collapse" : "Expand"} All
                    </button>
                </div>
            </div>
        </section>
    );
});

export default SpotlightBlock;
