import classNames from "classnames";
import styles from "./styles/SpotlightTerrace.module.scss";
import React, {KeyboardEvent, Suspense, useContext, useState} from "react";
import {observer} from "mobx-react";
import {getCdnUrl} from "../../../../utils/SchoolBlocksUtilities";
import ClickableLink from "../../../utilities/ClickableLink";
import {StoreContext} from "../../../../stores/StoreLoader";
import WatsonApi from "../../../../backends/WatsonApi";
import {runInAction} from "mobx";
import NotificationManager from "../../../notifications/NotificationManager";
import SpotlightTerraceTileCurationContainer from "./SpotlightTerraceTileCurationContainer";
import ImageComponent from "../../../utilities/ImageComponent";

const SpotlightTerraceTile = observer(({
    item, terraceObj, idx, setActiveIndex, dragHandleRef, dataActive, dataPosition, isExpanded, style,
}: {
   item: ISpotlightTile,
   terraceObj: ISpotlightTerrace,
   idx: number,
    setActiveIndex: (index: number) => void,
   dragHandleRef?: (element: HTMLElement | null) => void,
   dataPosition?: string,
   dataActive?: boolean,
   isExpanded?: boolean
   style?: React.CSSProperties
}
) => {
    const { userStore, modalStore, gridStore } = useContext(StoreContext);

    const [showContent, setShowContent] = useState(false);

    const tileClassName = classNames(
        styles.tile,
        {
            [styles.tileExpanded]: isExpanded,
            [styles.contentVisible]: showContent,
        }
    );



    const handleDelete = async () => {
        try {
            let updatedTiles = [...(terraceObj.json_data?.spotlight?.tiles || [])];
            const client = await WatsonApi();

            updatedTiles = updatedTiles.filter(tile => tile.id !== item.id);

            const response = await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: terraceObj.organization_id,
                id: terraceObj.id,
                data: { json_data: { spotlight: { tiles: updatedTiles } } },
            });
            const result = JSON.parse(response.data)

            runInAction(() => {
                gridStore.blocks.set(terraceObj.id, result);
            });
            NotificationManager.success("Tile successfully Deleted!");
            modalStore.removeTop();
        } catch (e) {
            console.error(e)
            NotificationManager.error("Something went wrong! Please try again.");
        }
    }
    const handleTileKeyDown = (e: KeyboardEvent) => {
        // Check which key was pressed
        if (e.key === 'Enter' || e.key === ' ') {
            // Prevent the page from scrolling if space is pressed
            e.preventDefault();
            // Toggle the content
            setShowContent(prev => !prev);
        }
    };

    return (
        <div
            className={tileClassName}
            tabIndex={0}
            onKeyDown={handleTileKeyDown}
            aria-expanded={showContent}
            data-active={dataActive}
            data-position={dataPosition}
            data-index={idx}
            style={style}
            onClick={() => {
                if (dataActive || isExpanded) {
                    setShowContent(prev => !prev)
                }
                else {
                    setActiveIndex(idx)
                }
            }} // Toggle content visibility on tile click
        >
            {userStore.isEditor && (
                <Suspense fallback={null}>
                    <SpotlightTerraceTileCurationContainer
                        item={item}
                        terraceObj={terraceObj}
                        idx={idx}
                        handleDelete={handleDelete}
                        dragHandleRef={dragHandleRef}
                    />
                </Suspense>
            )}

            <div className={styles.imageContainer}>
                <ImageComponent src={getCdnUrl(item.image)} alt={item.altText}/>
            </div>
            <div className={styles.bannerLayer}>
                <div className={styles.tileTitle}>
                    <h3>{item.title}</h3>
                </div>
            </div>

            {(item.message || item.buttonData.buttonToggle) && (
                <div className={styles.content}>
                    {item.message && <span className={styles.message}>{item.message}</span>}
                    {item.buttonData.buttonToggle && item.buttonData.buttonText && (item.buttonData.buttonLink || item.buttonData.buttonLinkOrgId) && (
                        <ClickableLink className={styles.tileButton} href={item.buttonData.buttonLink || `/pages/${item.buttonData.buttonLinkOrgId}`}>
                            <span>{item.buttonData.buttonText}</span>
                        </ClickableLink>
                    )}
                </div>
            )}
        </div>
    );
});

export default SpotlightTerraceTile;
